// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
.k-filtercell-operator {
    display: none;
}

.k-pager-numbers .k-link.k-selected {
    color: $primaryMain;
    background-color: $primaryLight;
}
.k-pager-numbers .k-link {
    color: $primaryMain;
}
.k-pager-numbers .k-link:hover,
.k-pager-numbers .k-link.k-hover {
    color: $primaryMain;
    background-color: $primary200;
}

#input-description {
    padding-left: 1rem;
}

#select-select-token-fees {
    padding: 11.55px 14px !important;
}
#select-select-token-payment {
    padding: 8px 10px !important;
}

// sweet alert
.swal2-container {
    z-index: 999999999 !important;
}

#re-captcha div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.myCustomList::-webkit-scrollbar {
    width: 5px;
    background-color: #b3b4b4;
}

.myCustomList::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 254, 254, 0.993);
    background-color: #ddd8d8af;
}
.myCustomList {
    margin-top: 5px;
    min-height: 65vh;
}

*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #b3b4b4;
}

*::-webkit-scrollbar-thumb {
    background-color: #ddd8d8af;
    border-radius: 50px;
}

// ==============================|| SLICK  ||============================== //
.slick-arrow:before {
    color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}
#button-upload-avatar {
    min-width: 4rem;
    padding: 0.4rem 0.625rem;
    background-color: $primaryDark;
    color: $paper;
    border-radius: 0.25rem;
    font-weight: 500;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.25s;
}
#button-upload-avatar:hover {
    opacity: 0.9;
}

input[type='file']::file-selector-button {
    border: none;
    cursor: pointer;
    color: $paper;
    background-color: $primaryDark;
    padding: 0.4rem 1rem;
    border-radius: 1rem;
    transition: 1s;
    margin-right: 1rem;
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}
